import { Pipe, PipeTransform } from '@angular/core';
import { LacertaWagtailPage } from '@lacerta/cms';

@Pipe({
  name: 'lacertaFormTarget',
})
export class LacertaFormTargetPipe implements PipeTransform {
  transform(page: LacertaWagtailPage): string {
    return new URL(page.meta.html_url).pathname;
  }
}
