import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lacerta-hamburger',
  template: `
    <input class="menu-btn" type="checkbox" id="menu-btn" [checked]="menuOpen" (change)="handleCheckChange($event)" />
    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
  `,
  styleUrls: ['./hamburger.component.scss'],
})
export class HamburgerComponent {
  @Input() menuOpen?: boolean;
  @Output() menuOpenChange = new EventEmitter();

  handleCheckChange(event: Event) {
    this.menuOpen = (event.currentTarget as HTMLInputElement)?.checked;
    this.menuOpenChange.emit(this.menuOpen);
  }
}
